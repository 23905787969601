<template>
  <Gospel :date="date" :show="show" />
  <div>
    <Button @btn-click="prevDay()" :text="'PREV'" :icon="'fas fa-arrow-left'" />
    <Button
      @btn-click="nextDay()"
      :text="'NEXT'"
      :icon="'fas fa-arrow-right'"
      :right="true"
    />
    <Button
      @btn-click="showText()"
      :text="show ? 'HIDE' : 'SHOW'"
      :icon="show ? 'far fa-eye-slash' : 'far fa-eye'"
    />
    <Button @btn-click="print()" :text="'PRINT'" :icon="'fas fa-print'" />
  </div>
</template>

<script>
import Gospel from './components/Gospel'
import Button from './components/Button'
import { Printd } from 'printd'

export default {
  name: 'App',
  components: {
    Gospel,
    Button,
  },
  data() {
    return {
      date: new Date(), // today
      show: true,
    }
  },
  methods: {
    nextDay() {
      process.env.VUE_APP_DEBUG_LOG &&
        console.log(':: debug :: App.method.nextDay()')

      this.date.setDate(this.date.getDate() + 1) // add day
      this.date = new Date(this.date.getTime()) // assign next day
    },
    prevDay() {
      process.env.VUE_APP_DEBUG_LOG &&
        console.log(':: debug :: App.method.prevDay()')

      this.date.setDate(this.date.getDate() - 1) // add day
      this.date = new Date(this.date.getTime()) // assign next day
    },
    showText() {
      process.env.VUE_APP_DEBUG_LOG &&
        console.log(':: debug :: App.method.showText()')
      this.show = !this.show
    },
    print() {
      const printD = new Printd()
      printD.print(document.getElementById('print_page'), [
        'p { font-family: Arial; font-size: 240%; margin: 0px } .pageHeader { margin: 0px; font-size: 1.4em; }',
      ])
    },
  },
  created() {
    process.env.VUE_APP_DEBUG_LOG && console.log(':: debug :: App.created()')
  },
  mounted() {
    process.env.VUE_APP_DEBUG_LOG && console.log(':: debug :: App.mounted()')
  },
}
</script>

<style>
#app {
  font-family: Arial;
  margin: 10px;
}
.mr-10 {
  margin-right: 10px;
}
</style>
