<template>
  <button @click="onClick()" class="button">
    <div v-if="right">{{ text }} <i :class="icon"></i></div>
    <div v-else><i :class="icon"></i> {{ text }}</div>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String,
    icon: String,
    align: String,
    right: Boolean,
  },
  methods: {
    onClick() {
      process.env.VUE_APP_DEBUG_LOG &&
        console.log(':: debug :: component.method.button.onClick()')
      this.$emit('btn-click')
    },
  },
}
</script>

<style scoped>
.button {
  margin: 20px 5px;
  background-color: #39495c;
  border-radius: 5px;
  font-size: 1em;
  width: 100px;
  height: 45px;
  color: white;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}
</style>
