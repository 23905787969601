<template>
  <div v-if="pages">
    <h1 class="title">
      {{ title }} / <small>{{ dateYYYMMDD }}</small>
    </h1>
    <a :href="url" target="reference" class="reference">
      <i class="fas fa-external-link-alt mr-10"></i> Reference
    </a>
    <div id="print_page">
      <div v-for="page in pages" :key="page" class="paragraph">
        <p class="pageHeader print-only">{{ dateYYYMMDD }} / {{ title }}</p>
        <p v-html="formatPage(page)"></p>
      </div>
    </div>
  </div>
  <div v-else>
    <h1>Loading...</h1>
  </div>
</template>

<script>
import axios from 'axios'
import chunk from 'lodash.chunk'

const PARAGRAPH_MAX_LINE = 10000
const PARAGRAPH_MAX_LENGTH = 35
const API_URL = 'https://cf-gospel-production.hawooni.workers.dev'

/*eslint-disable */
const SHOW_WORDS = ['and']
const SHOW_CHARS = ['‘', '’', ',', '.', '”', '“', '!', '?', "'", ':', '"', ';', '-', '–', '—', '(', ')']
/*eslint-enable */

axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  name: 'Gospel',
  props: {
    date: {
      type: Date,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: null,
      pages: null,
      url: null,
    }
  },
  watch: {
    date() {
      process.env.VUE_APP_DEBUG_LOG &&
        console.log(':: debug :: component.gospel.watch.date()')

      this.setData()
    },
  },
  methods: {
    /**
     * @returns {Promise}
     */
    setData() {
      process.env.VUE_APP_DEBUG_LOG &&
        console.log(':: debug :: component.gospel.method.setData()')

      return axios.get(API_URL + '/' + this.dateYYYMMDD).then((res) => {
        this.title = res.data.title
        this.pages = this._stringArrayToChunkLimit(
          res.data.gospel,
          PARAGRAPH_MAX_LENGTH,
          PARAGRAPH_MAX_LINE
        )
        this.url = res.data.url
      })
    },
    /**
     * @param {String[]} words
     * @returns {String}
     */
    formatPage(paragraphs) {
      process.env.VUE_APP_DEBUG_LOG &&
        console.log(':: debug :: component.gospel.method.formatPage()')

      let output = ''

      paragraphs.forEach((paragraph) => {
        if (paragraph.length > 0) {
          if (this.show) {
            output += paragraph + '<br/>'
          } else {
            let hiddenParagraph = ''
            let words = paragraph.split(' ') // include special chars

            words.forEach((word, index) => {
              const fWord = word.toLowerCase().replace(/[^a-z]+/gi, '') // filter only alphabet

              if (index === 0) {
                hiddenParagraph = word
              } else if (SHOW_WORDS.includes(fWord)) {
                hiddenParagraph += ' ' + word + ' '
              } else {
                hiddenParagraph += ' ' + this._getStrEmptyLine(word)
              }
            })
            output += hiddenParagraph + '<br/>'
          }
        } else {
          output += '<br/>'
        }
      })

      return output.trimRight()
    },

    /**
     * convert word to empty char except SHOW_CHARS
     *
     * @param {String} word
     * @param {Char} emptyChar
     * @returns {String}
     */
    _getStrEmptyLine(word, emptyChar = '_') {
      let emptyLine = ''
      let wordChars = [...word]

      wordChars.forEach((char) => {
        if (SHOW_CHARS.includes(char) || char.match(/[0-9]/)) {
          emptyLine += char
        } else {
          emptyLine += emptyChar
        }
      })

      return emptyLine // extra line
    },

    /**
     * @param {String[]} strArr
     * @param {Integer} maxLength
     * @param {Integer} limit
     */
    _stringArrayToChunkLimit(strArr, maxLength, limit) {
      process.env.VUE_APP_DEBUG_LOG &&
        console.log(
          ':: debug :: component.gospel.method._stringArrayToChunkLimit()'
        )

      const sumStrArr = []
      const arrStrArrMaxLength = strArr.map((str) =>
        this._stringToArrayWithMaxLength(str, maxLength)
      )

      arrStrArrMaxLength.forEach((StrArrMaxLength) => {
        sumStrArr.push(...StrArrMaxLength, '') // '' => start new paragraph
      })
      sumStrArr.pop() // trim last ''

      return chunk(sumStrArr, limit)
    },

    /**
     * @param {String} string
     * @param {Integer} maxLength
     * @returns {Array}
     */
    _stringToArrayWithMaxLength(string, maxLength = 50) {
      process.env.VUE_APP_DEBUG_LOG &&
        console.log(
          ':: debug :: component.gospel.method._stringToArrayWithMaxLength()'
        )

      let arrStr = []
      let tmpStr = ''

      string.split(' ').forEach((chunk) => {
        if (tmpStr.length + chunk.length > maxLength) {
          arrStr.push(tmpStr.trimRight())
          tmpStr = chunk + ' '
        } else {
          tmpStr += chunk + ' '
        }
      })
      arrStr.push(tmpStr.trimRight())

      return arrStr
    },
  },
  computed: {
    dateYYYMMDD() {
      return `${this.date.toISOString().split('T')[0]}`
    },
  },
  created() {
    process.env.VUE_APP_DEBUG_LOG &&
      console.log(':: debug :: component.gospel.created()')
    return this.setData()
  },
  mounted() {
    process.env.VUE_APP_DEBUG_LOG &&
      console.log(':: debug :: component.gospel.mounted()')
  },
}
</script>

<style scoped>
h1 {
  margin-bottom: 35px;
}
a {
  text-decoration: none;
}
.paragraph p {
  line-height: 1.4em;
}
.paragraph {
  margin: 25px 5px;
  font-size: 150%;
}
.pageHeader {
  margin: 0px;
  font-size: 0.7em;
}
.reference {
  margin: 0px 5px;
}
.print-only {
  display: none;
}
.title {
  margin-bottom: 15px;
}

@media print {
  .no-print {
    display: none;
  }
  .print-only {
    display: block;
  }
}
</style>
